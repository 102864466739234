import React, { useState, useRef, useEffect } from 'react';
import { Input, List, Avatar, Button, Spin, Alert } from 'antd';
import { DeleteOutlined, UserOutlined, RobotOutlined } from '@ant-design/icons';
import './Retrieval.css';
import ClauseGallery from './ClauseGallery';
import Landing from './Landing';
import SubHeader from './SubHeader';
import ReactMarkdown from 'react-markdown';
import { ReactComponent as SendSvg  } from '../assets/icons/send.svg';
import { templates } from '../utils/SuggestionTemplates';
import FeedbackPopup from './FeedbackPopup';
import {ReactComponent as BadSvg} from '../assets/icons/BadSvg.svg';
import { ReactComponent as GoodSvg } from '../assets/icons/GoodSvg.svg';
import { Icon } from "../utils/Icon";
const { TextArea } = Input;

function Retrieval({ messages, setMessages }) {
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const messageListRef = useRef(null);
  const contentUpdatedRef = useRef(false);
  const [currentIndex, setCurrentIndex] = useState(1);
  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);
  useEffect(() =>{
    if (contentUpdatedRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
      contentUpdatedRef.current = false;
    }
  });

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  async function handleSubmit() {
    if (inputMessage.trim() === '') return;
    try {
      setIsLoading(true);
      const input = {"query": inputMessage, "isSourceNeeded": true}
      const userMessage = { text: inputMessage, isUser: true, isFakeMessage: false };

      const newChatbotMessage = { text: '', isUser: false, isFakeMessage: false, ifDisplayClause: false,  feedback:{type:'', detail:''}};

      setMessages([...messages, userMessage, newChatbotMessage]);
      setInputMessage('');
      // send request to get the text response
      const text_url = process.env.REACT_APP_GET_CHAT_RESPONSE;
      const fetchTextOptions = {
          method: 'POST',
          headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(input),
      };
      // send request to get the relevant clauses
      const clause_url = process.env.REACT_APP_GET_SOURCE_DOCS;
      const fetchClauseOptions = {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
      };
      fetch(text_url, fetchTextOptions).then(response => {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        function read(lastChunk, noPrint) {
          return reader.read().then(async ({ done, value }) => {
            if (done) {
              try {
                const json = JSON.parse(lastChunk);
                newChatbotMessage['docs'] = json.docs;
                newChatbotMessage['ifDisplayClause'] = true;
                setMessages([...messages, userMessage, newChatbotMessage]);
                contentUpdatedRef.current = true;
              } catch (e) {
                console.log(e);
              }
              setIsLoading(false);
              return;
            }
	    if (noPrint){
		    lastChunk += decoder.decode(value);
	    }
	    else{
		    // Process the streamed data
		    lastChunk = decoder.decode(value);
		    let newText = newChatbotMessage['text'] + lastChunk
		    //TODO: hardcode
		    var docsIndex = newText.indexOf('{"docs":');
		    var beforeDocs = '';
		    var afterDocs = '';
		    if (docsIndex !== -1) {
			  beforeDocs = newText.substring(0, docsIndex);
			  afterDocs = newText.substring(docsIndex);
			  newText = beforeDocs
			  lastChunk = afterDocs
			  noPrint = true
		    }
		    newChatbotMessage['text'] = newText
		    setMessages([...messages, userMessage, newChatbotMessage]);
		    contentUpdatedRef.current = true;
	    }
            // Continue reading the stream
            return read(lastChunk, noPrint);
          });
        }
        // Start reading the stream
        return read('', false);
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);
        newChatbotMessage['text'] = '';
        setMessages([...messages, userMessage, newChatbotMessage]);
      });
    } catch(error) {
      console.error(error);
      alert(error.message);
    }
    };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleGoodFeedback = ()=>{
    setOpenFeedback(true)
  }

  const handleBadFeedback = () => {
    setOpenFeedback(true)
  }

  return (
    <>
    <SubHeader />
    <div className="retrieval-chatbox-container">
      <div className="message-list" ref={messageListRef}>
      {messages.length > 0 ? (
        <div>
        <List
          itemLayout="horizontal"
          dataSource={[...messages, { isFakeMessage: true }]}
          renderItem={(message, index) => (
            message.isFakeMessage
            ? <List.Item className='list-item fake-message'>
                <List.Item.Meta
                  description={
                    !isLoading ? (
                      <div className="clear-button-wrapper">
                        <Button className='clear-button' onClick={() => setMessages([])}>
                          Clear history
                          <DeleteOutlined style={{color:'#A72841'}}/>
                        </Button>
                      </div>
                    ) : null}>
                </List.Item.Meta>
              </List.Item>
            : <List.Item className={message.isUser ? 'list-item user-message' : 'list-item chatbot-message'}>
              <List.Item.Meta
                avatar={<Avatar icon={message.isUser ?  <UserOutlined /> : <RobotOutlined />} />}
                description={message.isUser ? (
                  <div className="user-message-description">
                    {message.text}
                  </div>
                ) : (
                  (isLoading && message.text == '')
                  ? <Spin/>
                  : ((message.text == "") ?
                      <Alert
                        className='alert'
                        message="Error"
                        type="error"
                        showIcon
                        description=
                            "An error occurred. Either the engine you requested does not exist or there was another issue processing your request."/>
                    : <>
                    <div style={{marginBottom: '15px', color: 'black', padding:'0', marginTop:'-15px'}}>
                        <ReactMarkdown children={message.text} />
                        {(!message.isUser && message.ifDisplayClause) ? <div style={{paddingTop:'20px'}}><ClauseGallery clauses={message.docs}/></div> : null}
                    </div>
                    <div className='feedback-icon'>
                      {
                        <div>
                        <Icon onClick={()=>{if (message.feedback.type != 'good') {message.feedback.type = 'good'; setCurrentIndex(index); handleGoodFeedback();}}} className='feedback-icon-item' size='13' style={{'paddingRight':'5px'}} color={message.feedback.type == 'good'?'#A72841':'grey'} icon={GoodSvg}/>
                        <Icon onClick={()=>{if (message.feedback.type != 'bad') {message.feedback.type = 'bad'; setCurrentIndex(index); handleBadFeedback();}}} className='feedback-icon-item' size='13' color={message.feedback.type == 'bad'?'#A72841':'grey'} icon={BadSvg}/>
                        </div>
                      }
                    </div>
                    {openFeedback && <FeedbackPopup feedbackType={message.feedback.type} open={openFeedback} setOpen={setOpenFeedback} messages={messages} index={currentIndex} mode={"retrieval"}/>}
                  </>))
                }
              />
            </List.Item>
          )}
        />
        </div>
      ) : (
        <Landing handleClick={(text) => setInputMessage(text)} suggestions={templates['General']}/>
      )}
      </div>
      <div>
         <Input.TextArea
            className='textarea'
            value={inputMessage}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            autoSize
            placeholder="Please type your question here, use Shift+Enter for line break"
            style={{padding: '0.25vh', fontSize: '1.1em'}}
            suffix={<SendSvg />}>
          </Input.TextArea>
          <Button
            className='search-button'
            type="text"
            shape="circle"
            icon={<SendSvg />}
            loading={isLoading}
            size='large'
            onClick={handleSubmit}
            disabled={inputMessage.trim() === ''}/>
      </div>
      <div className='opacity-block-top'></div>
      <div className='opacity-block-bottom'></div>
    </div>
    </>
  );
}

export default Retrieval;

