import React, { useState, useRef, useEffect } from 'react';
import { Input, List, Avatar, Button, Spin, Alert, Select } from 'antd';
import { DeleteOutlined, UserOutlined, RobotOutlined } from '@ant-design/icons';
import './Consultant.css';
import './FeedbackPopup.css'
import Landing from './Landing';
import ReactMarkdown from 'react-markdown';
import { ReactComponent as SendSvg } from '../assets/icons/send.svg';
import modePrompts from '../utils/modePrompts';
import { templates } from '../utils/SuggestionTemplates';
import { ReactComponent as GoodSvg } from '../assets/icons/GoodSvg.svg';
import { Icon } from "../utils/Icon";
import {ReactComponent as BadSvg} from '../assets/icons/BadSvg.svg';
import FeedbackPopup from './FeedbackPopup';

const { TextArea } = Input;


function Consultant({ messages, setMessages, mode, setMode }) {
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const messageListRef = useRef(null);
  const contentUpdatedRef = useRef(false);
  const [currentIndex, setCurrentIndex] = useState(1);
  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);
  useEffect(() =>{
    if (contentUpdatedRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
      contentUpdatedRef.current = false;
    }
  });

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  async function handleSubmit() {
    if (inputMessage.trim() === '') return;
    try {
      setIsLoading(true);
      const input = {"query": modePrompts[mode] + inputMessage, "isSourceNeeded": false}
      const userMessage = { text: inputMessage, isUser: true, isFakeMessage: false };

      const newChatbotMessage = { text: '', isUser: false, isFakeMessage: false, feedback:{type:'', detail:''} };

      setMessages([...messages, userMessage, newChatbotMessage]);
      setInputMessage('');
      // send request to get the text response
      const text_url = process.env.REACT_APP_GET_CHAT_RESPONSE;
      const fetchTextOptions = {
          method: 'POST',
          headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(input),
      };
      fetch(text_url, fetchTextOptions).then(response => {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        function read() {
          return reader.read().then(({ done, value }) => {
            if (done) {
              return;
            }
            setIsLoading(false);
            newChatbotMessage['text'] += decoder.decode(value);
            setMessages([...messages, userMessage, newChatbotMessage]);
            return read();
          });
        }
        return read();
      })
      .catch(error => {
        console.error('Error:', error);
      });
    } catch(error) {
      console.error(error);
      alert(error.message);
    }

    // // dummy msg
    // const userMessage = { text: inputMessage, isUser: true, isFakeMessage: false };
    // const newChatbotMessage = { text: 'GPT response', isUser: false, isFakeMessage: false, feedback:{type:'', detail:''}};
    // setInputMessage('')
    // setMessages([...messages, userMessage, newChatbotMessage]);
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };
  const handleClearHistory = () => {
    setMessages([])
  }
  const handleModeChange = (value) => {
    setMode(value);
  }

  const handleGoodFeedback = ()=>{
    setOpenFeedback(true)
  }

  const handleBadFeedback = () => {
    setOpenFeedback(true)
  }

  return (
    <>
    <div className='consultant-header'>
     <Select
            defaultValue={mode}
            className='selector'
            onChange={handleModeChange}
            bordered={false}
            options={Object.keys(templates).map(key => ({
              value: key,
              label: key
            }))}
          />
    </div>
    <div className="consultant-chatbox-container">
      <div className="message-list" ref={messageListRef}>
      {messages?.length > 0 ? (
        <div>
        <List
          itemLayout="horizontal"
          dataSource={[...messages, {text:'', isFakeMessage: true}]}
          renderItem={(message, index) => (
            message.isFakeMessage
            ? <List.Item className='list-item fake-message'>
                <List.Item.Meta
                  description={
                    !isLoading ? (
                      <div className="clear-button-wrapper" id="clear-history" style={{padding: '0'}}>
                        <Button className='clear-button' onClick={handleClearHistory} >
                          Clear history
                          <DeleteOutlined style={{color:'#A72841'}}/>
                        </Button>
                      </div>
                    ) : null}>
                </List.Item.Meta>
              </List.Item>
            : <List.Item className={message.isUser ? 'list-item user-message' : 'list-item chatbot-message'}>
              <List.Item.Meta
                avatar={<Avatar icon={message.isUser ?  <UserOutlined /> : <RobotOutlined />} />}
                description={message.isUser ? (
                  <div style={{color: 'black'}}>
                    {message.text}
                  </div>
                ) : (
                  (isLoading && message.text == '')
                  ? <Spin/>
                  : ((message.text == "") ?
                      <Alert
                        className='alert'
                        message="Error"
                        type="error"
                        showIcon
                        description=
                        "An error occurred. Either the engine you requested does not exist or there was another issue processing your request."/>
                    : <>
                      <div style={{marginBottom: '15px', color: 'black', padding:'0', marginTop:'-15px'}}>
                        <ReactMarkdown children={message.text} />
                      </div>
                      <div className='feedback-icon'>
                      {
                        <div>
                        <Icon onClick={()=>{if (message.feedback.type != 'good') {message.feedback.type = 'good'; setCurrentIndex(index); handleGoodFeedback();}}} className='feedback-icon-item' size='13' style={{'paddingRight':'5px'}} color={message.feedback.type == 'good'?'#A72841':'grey'} icon={GoodSvg}/>
                        <Icon onClick={()=>{if (message.feedback.type != 'bad') {message.feedback.type = 'bad'; setCurrentIndex(index); handleBadFeedback();}}} className='feedback-icon-item' size='13' color={message.feedback.type == 'bad'?'#A72841':'grey'} icon={BadSvg}/>
                        </div>
                      }
                    </div>
                      {openFeedback && <FeedbackPopup feedbackType={message.feedback.type} open={openFeedback} setOpen={setOpenFeedback} messages={messages} index={currentIndex} mode={"consultant"}/>
                    }
                    </>
                    ))
                }
              />
            </List.Item>
          )}
        />
        </div>
      ) : (
          <Landing handleClick={(text) => setInputMessage(text)} suggestions={templates[mode]} />
      )}
      </div>
      <div>
        <TextArea
            className='textarea'
            value={inputMessage}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            autoSize
            placeholder="Please type your question here, use Shift+Enter for line break"
            style={{padding: '0.25vh', fontSize: '1.1em'}}>
            </TextArea>
          <Button
            className='search-button'
            type="text"
            shape="circle"
            icon={<SendSvg />}
            loading={isLoading}
            size='large'
            onClick={handleSubmit}
            disabled={inputMessage.trim() === ''}/>
      </div>
      <div className='opacity-block-top'></div>
      <div className='opacity-block-bottom'></div>
    </div>
    </>
  );
}

export default Consultant;